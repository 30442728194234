import { Tag } from './tag'
import Link from 'next/link'
import { Company } from 'api/types'

export function CompanyCard({ id, name, jobs }: Company) {
  return (
    <Link href={`/companies/${id}`}>
      <a className='w-auto border border-black rounded-xl p-3.5 flex cursor-pointer hover:bg-primary hover:border-primary hover:text-white'>
        {/* <div className='flex-shrink-0 bg-gray-400 w-15 h-15'>
          <img
            src='xxxxx'
            alt='company_logo'
          />
        </div> */}
        <div className='flex-grow flex flex-col ml-2.5'>
          <h3 className='font-bold text-lg leading-tight cursor-pointer text-current'>{name}</h3>
          <h4 className='mt-2.5 text-md leading-5 text-current'>{jobs} jobs</h4>
        </div>
      </a>
    </Link>
  )
}
