import { useState, useEffect, useRef, ChangeEventHandler } from 'react'
import classNames from 'classnames'
import { useDebouncedInput } from 'ui/hooks/input'

interface AutocompleteInputProps {
  label?: string
  name?: string
  error?: string | false
  detail?: string
  initialValue?: string
  placeholder?: string
  required?: boolean
  disabled?: boolean
  options?: [{ key: string; value: any }] | []
  setQuery: (query: string) => void
  handleSelect: (value: any) => string
}

export const AutocompleteInput = ({
  label,
  placeholder,
  required,
  options = [],
  disabled = false,
  initialValue,
  detail,
  error,
  setQuery,
  handleSelect
}: AutocompleteInputProps) => {
  const containerRef = useRef<any>()
  const [inputText, setInputText] = useState(initialValue || '')
  const [showMenu, setShowMenu] = useState(false)

  const debouncedQuery: string = useDebouncedInput(inputText, 250)

  // Set parent query
  useEffect(() => {
    if (inputText !== '') setQuery(debouncedQuery)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedQuery])

  // Handle Click Outside
  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        if (showMenu) setShowMenu(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    document.addEventListener('touchstart', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('touchstart', handleClickOutside)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef, showMenu])

  function handleChange({ target }: any) {
    if (target.value === '') {
      handleSelect({ city: '', state: '' })
    }
    setInputText(target.value)
  }

  return (
    <div className='flex flex-col relative' ref={containerRef}>
      {label && (
        <label className='input-label'>
          {label}
          {required && <span className='text-primary'>*</span>}
        </label>
      )}
      <div>
        <input
          disabled={disabled}
          placeholder={placeholder}
          className='input-text'
          value={disabled ? '' : inputText}
          onChange={handleChange}
          onFocus={() => setShowMenu(true)}
        />
        <div
          className={classNames(
            'autocomplete-menu',
            label && 'mt-7',
            (!showMenu || !options.length || !inputText.length) && 'hidden'
          )}
        >
          {options.map(({ key, value }) => (
            <div
              className='p-2.5 cursor-pointer hover:bg-gray-200 rounded'
              key={key}
              onClick={() => {
                setInputText(handleSelect(value))
                setShowMenu(false)
              }}
            >
              {key}
            </div>
          ))}
        </div>
      </div>
      {detail && <p className='mt-1 text-xs text-gray-600'>{detail}</p>}
      {error && <p className='mt-1 text-xs text-red-600'>{error}*</p>}
    </div>
  )
}
