import Link from 'next/link'

export type TagProps = {
  text: string
}

export function Tag({ text }: TagProps) {
  return (
    <Link href={`/tags/${text}`}>
      <a className='cursor-pointer leading-tight lowercase text-primary text-xs rounded bg-primary-light p-1.5 hover:bg-primary hover:text-white'>
        {text}
      </a>
    </Link>
  )
}
