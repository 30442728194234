import { useState, ChangeEventHandler } from 'react'
import { slugify } from 'lib/utils'
import classNames from 'classnames'
import { useField } from 'formik'

export type InputProps = {
  placeholder: string
  type?: 'text' | 'email' | 'password'
  name?: string
  autoComplete?: string
  required?: boolean
  onChange?: ChangeEventHandler<HTMLInputElement>
  roundedTop?: boolean
  roundedBottom?: boolean
}

export type TextAreaProps = {
  placeholder: string
  rows?: number
  name?: string
  autoComplete?: string
  required?: boolean
  onChange?: ChangeEventHandler<HTMLTextAreaElement>
  className: string
}

export type EmailInputProps = {
  name?: string
  required?: boolean
  onChange?: ChangeEventHandler<HTMLInputElement>
  roundedTop?: boolean
  roundedBottom?: boolean
}

export type PasswordInputProps = {
  name?: string
  required?: boolean
  onChange?: ChangeEventHandler<HTMLInputElement>
  roundedTop?: boolean
  roundedBottom?: boolean
}

export type InputGroupProps = {
  className?: string
  children?: JSX.Element | JSX.Element[]
}

export function EmailInput(props: EmailInputProps) {
  return <Input placeholder='Email Address' type='email' autoComplete='email' {...props} />
}

export function PasswordInput(props: EmailInputProps) {
  return <Input placeholder='Password' type='password' autoComplete='current-password' {...props} />
}

export function InputGroup(props: InputGroupProps) {
  return (
    <div className={classNames('rounded-md shadow-sm -space-y-px', props.className)}>
      {props.children}
    </div>
  )
}

export function Input(props: InputProps) {
  const [id] = useState(() => slugify(props.placeholder))

  return (
    <div>
      <label htmlFor={id} className='sr-only'>
        {props.placeholder}
      </label>
      <input
        id={id}
        name={props.name}
        type={props.type ?? 'text'}
        autoComplete={props.autoComplete}
        required={props.required}
        onChange={props.onChange}
        className={classNames(
          'appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm',
          props.roundedBottom && 'rounded-b-md',
          props.roundedTop && 'rounded-t-md'
        )}
        placeholder={props.placeholder}
      />
    </div>
  )
}

export function TextArea(props: TextAreaProps) {
  const [id] = useState(() => slugify(props.placeholder))

  return (
    <div>
      <label htmlFor={id} className='sr-only'>
        {props.placeholder}
      </label>
      <textarea
        id={id}
        rows={props.rows ?? 3}
        name={props.name}
        autoComplete={props.autoComplete}
        required={props.required}
        onChange={props.onChange}
        className={classNames(
          'shadow-sm focus:ring-red-500 focus:border-red-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md',
          props.className
        )}
        placeholder={props.placeholder}
      />
    </div>
  )
}

export type FormTextInputProps = {
  label?: string
  type?: 'text' | 'email' | 'password'
  name?: string
  value?: string
  placeholder?: string
  autoComplete?: string
  required?: boolean
  onChange?: ChangeEventHandler<HTMLInputElement>
  roundedTop?: boolean
  roundedBottom?: boolean
  disabled?: boolean
  hidden?: boolean
  detail?: string
  error?: string | false
}

export function TextInput({
  label,
  required,
  roundedBottom,
  roundedTop,
  detail,
  error,
  ...props
}: FormTextInputProps) {
  return (
    <div className={classNames('flex flex-col', props.hidden ? 'hidden' : null)}>
      {label && (
        <label className='input-label'>
          {label}
          {required && <span className='text-primary'>*</span>}
        </label>
      )}
      <input {...props} id={`input-${props.name}`} className='input-text' />
      {detail && <p className='mt-1 text-xs text-gray-600'>{detail}</p>}
      {error && <p className='mt-1 text-xs text-red-600'>{error}*</p>}
    </div>
  )
}

export function FormTextInput(props: FormTextInputProps) {
  const [field, meta] = useField(props?.name ?? '')
  return <TextInput {...field} {...props} error={meta.touched && (props.error || meta.error)} />
}

export default Input
