import { Fragment, useEffect, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { useField } from 'formik'
import classNames from 'classnames'

export type SelectInputProps = {
  options?: { id: string; name: string }[]
  label: string
  error?: string | false
  value?: { id: string; name: string }
  placeholder?: string
  required?: boolean
  onChange?: (value: any) => void
  name: string
  disabled?: boolean
  hidden?: boolean
  detail?: string
}

export function SelectInput({
  options = [],
  label,
  required,
  error,
  value,
  placeholder,
  onChange = () => {},
  name,
  ...props
}: SelectInputProps) {
  return (
    <div className={classNames('flex flex-col', props.hidden ? 'hidden' : null)}>
      {label && (
        <label className='input-label'>
          {label}
          {required && <span className='text-primary'>*</span>}
        </label>
      )}
      <Listbox
        value={value}
        onChange={(val) => {
          if (val) onChange(val)
        }}
      >
        <div className='relative'>
          <Listbox.Button className='leading-none rounded relative w-full p-2.5 border text-lg border-gray-600 placeholder-gray-600 text-black text-left cursor-default focus:outline-none focus:ring-primary focus:border-primary focus:z-10'>
            {value?.name.length ? (
              <span className='block text-lg leading-tight'>{value.name}</span>
            ) : (
              <span className='block text-lg text-gray-600 leading-tight'>Select...</span>
            )}
            <span className='absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
              <SelectorIcon className='h-5 w-5 text-gray-400' aria-hidden='true' />
            </span>
          </Listbox.Button>
          ​
          <Transition
            as={Fragment}
            leave='transition ease-in duration-100'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Listbox.Options className='absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-md ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none'>
              {options.map((option) => (
                <Listbox.Option
                  key={option.id}
                  className={({ active }) =>
                    classNames(
                      active ? 'text-white bg-primary' : 'text-gray-900',
                      'cursor-default select-none relative py-2 pl-3 pr-9'
                    )
                  }
                  value={option}
                >
                  {({ selected, active }) => (
                    <>
                      <span
                        className={classNames(
                          selected ? 'font-semibold' : 'font-normal',
                          'block truncate'
                        )}
                      >
                        {option.name}
                      </span>
                      {selected ? (
                        <span
                          className={classNames(
                            active ? 'text-white' : 'text-primary',
                            'absolute inset-y-0 right-0 flex items-center pr-4'
                          )}
                        >
                          <CheckIcon className='h-5 w-5' aria-hidden='true' />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
      {error && <p className='mt-1 text-xs text-red-600'>Field is required.*</p>}
    </div>
  )
}

export function FormSelectInput({ onChange, ...props }: SelectInputProps) {
  const [field, meta] = useField(props?.name ?? '')
  return (
    <SelectInput
      {...field}
      {...props}
      error={meta.touched && (props.error || meta.error)}
      onChange={(val) => {
        onChange?.(val)
        field.onChange({ target: { value: val, name: props.name }, name: props.name })
      }}
    />
  )
}

export default SelectInput
