import { useState, ChangeEventHandler, useEffect } from 'react'

export function useTextInput(defaultValue?: string): {
  value: string | undefined
  onChange: ChangeEventHandler<HTMLInputElement>
} {
  const [value, setValue] = useState(defaultValue)
  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setValue(e.target.value)
  }
  return { value, onChange }
}

export function useTextAreaInput(defaultValue?: string): {
  value: string | undefined
  onChange: ChangeEventHandler<HTMLTextAreaElement>
} {
  const [value, setValue] = useState(defaultValue)
  const onChange: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    setValue(e.target.value)
  }
  return { value, onChange }
}

export function useCheckedInput(defaultValue?: boolean): {
  checked: boolean | undefined
  onChange: ChangeEventHandler<HTMLInputElement>
} {
  const [checked, setChecked] = useState(defaultValue)
  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setChecked(e.target.checked)
  }
  return { checked, onChange }
}

export const useDebouncedInput = (value: string, delay: number): string => {
  const [debouncedValue, setDebouncedValue] = useState(value)
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])

  return debouncedValue
}
