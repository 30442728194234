import { RefreshIcon } from '@heroicons/react/solid'
import classNames from 'classnames'

export type PrimaryButtonProps = {
  type?: 'submit' | 'button' | 'reset' | undefined
  onClick?: () => void
  loading?: boolean
  children: string | JSX.Element
  className?: string
  disabled?: boolean
}

export function PrimaryButton({
  type = 'submit',
  onClick,
  loading = false,
  className,
  disabled = false,
  children
}: PrimaryButtonProps) {
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled || loading}
      className={classNames('btn', className)}
    >
      {loading ? (
        <RefreshIcon className='animate-spin h-4 w-4 text-current mx-auto' aria-hidden='true' />
      ) : (
        children
      )}
    </button>
  )
}
