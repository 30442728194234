import { useState, ChangeEventHandler } from 'react'
import { slugify } from 'lib/utils'
import classNames from 'classnames'
import { useField } from 'formik'

export type CheckboxProps = {
  label: string
  checked?: boolean
  name?: string
  bold?: boolean
  onChange?: ChangeEventHandler<HTMLInputElement>
  className?: string
}

export function Checkbox(props: CheckboxProps) {
  const [id] = useState(() => slugify(props.label))

  return (
    <div className='flex items-center'>
      <input
        id={id}
        type='checkbox'
        className='h-4 w-4 text-primary focus:ring-primary border-gray-600 rounded'
        checked={props.checked}
        name={props?.name}
        onChange={props?.onChange}
      />
      <label
        htmlFor={id}
        className={classNames(
          'ml-2 block text-sm text-gray-900',
          props.bold && 'tracking-wide font-bold',
          props.className
        )}
      >
        {props.label}
      </label>
    </div>
  )
}

export type FormCheckboxProps = {
  label: string
  checked?: boolean
  name?: string
  value?: string
  type?: string
  onChange?: ChangeEventHandler<HTMLInputElement>
  className?: string
}

export function CheckboxInput({ type = 'checkbox', ...props }: FormCheckboxProps) {
  return (
    <div className='flex items-center'>
      <input
        {...props}
        id={`input-${props.name}`}
        type={type}
        className={classNames(
          'h-4 w-4 text-primary focus:ring-transparent focus:ring-0 border-gray-600',
          type === 'checkbox' && 'rounded-sm'
        )}
      />
      <label
        className={classNames(
          'ml-2 block text-sm text-gray-900 tracking-wide font-bold',
          props.className
        )}
      >
        {props.label}
      </label>
    </div>
  )
}

export function FormCheckbox(props: FormCheckboxProps) {
  const [field] = useField({ name: props?.name ?? '', type: props?.type ?? 'checkbox' })

  return <CheckboxInput {...field} {...props} />
}
