import { Tag } from './tag'
import Link from 'next/link'
import { Job } from 'api/types'

export function JobCard({ id, title, location, tags, company }: Job) {
  return (
    <Link href={`/posts/${id}`}>
      <a className={'w-100 border-black border-b py-5 flex items-center gap-2.5'}>
        {/* <div className='bg-gray-400 flex-shrink-0 w-10 h-10'>
          <img
            src='xxxxx'
            alt='company_logo'
          />
        </div> */}
        <div className='flex-grow flex flex-col overflow-hidden'>
          <h3 className='block truncate font-bold text-lg leading-tight cursor-pointer hover:underline '>
            {title}
          </h3>
          <h4 className='t-0.5 inline-block text-md leading-5'>{company?.name ?? '--'}</h4>
        </div>
        <div className='hidden flex-shrink-0 w-1/4 flex-wrap gap-2.5 md:flex'>
          {tags.map((tag) => (
            <Tag key={tag} text={tag} />
          ))}
        </div>
        <div className='hidden flex-shrink-0 w-1/5 text-right text-primary md:flex md:justify-end'>
          {location ? (
            <>
              <Link href={`/locations/${location.city}`}>
                <a className='hover:underline'>{location.city}</a>
              </Link>
              ,&nbsp;
              <Link href={`/locations/${location.state}`}>
                <a className='hover:underline'>{location.state}</a>
              </Link>
            </>
          ) : (
            <span>Remote</span>
          )}
        </div>
      </a>
    </Link>
  )
}
